import Parse from "parse"
import { parseLimitRequest } from "../../utils"

export const ProductionNeeds = Parse.Object.extend("ProductionNeeds")

export const getProductionNeedsByProductionDate = async ({
	productionDate,
	toJSON = true,
}) => {
	try {
		const productionNeeds = await new Parse.Query(ProductionNeeds)
			.equalTo("stepProductionDate", productionDate)
			.include(["supplierItem"])
			.limit(parseLimitRequest)
			.find()

		return toJSON ? productionNeeds.map(productionNeed => productionNeed.toJSON()) : productionNeeds
	} catch (e) {
		return Promise.reject(e)
	}
}
