import React from "react"
import { Stack, Typography, Box, Chip } from "@mui/material"

import { getPSEStatusLabel, getPSESubStatusLabel, isPSEInsufficientWeight } from "../../../utils/productionStepExecution"
import { getTransformationTypeLabel } from "../../../utils/supplierItemUtils"
import ProductionStepExecutionStatus from "../ProductionStepExecutionsStatus"

import { PEPSEResumeContainer } from "./StyledExecutionPreviewComponents"
import { StyledItemValue, StyledItemLabel } from "../styledComponents"
import CommentsIconBadge from "../../../components/Comments/CommentsIcon"

const sx = {
	title: {
		fontSize: 20,
		lineHeight: 1,
		color: "#000",
		fontWeight: 500,
	},
	commentsIconContainer: {
		position: "absolute",
		right: 24,
		top: 12,
		color: "rgba(85 85 85)"
	}
}

const PSEResume = ({ 
	name,
	section,
	recipe,
	status,
	transformation,
	uniqueCode,
	subStatus,
	onClickComments = null,
	commentsCount = 0
 }) => {
	return (
		<PEPSEResumeContainer sx={{ position: "relative" }}>
			<Stack spacing={4}>
				{/* head */}
				<Stack spacing={2.2} direction="row" alignItems="center">
					{isPSEInsufficientWeight(subStatus) && (
						<Chip label={getPSESubStatusLabel(subStatus)} color="error" />
					)}
					<Typography variant="h1" sx={sx.title}>
						{name}
					</Typography>
					<ProductionStepExecutionStatus
					statusValue={status}
					statusName={getPSEStatusLabel(status)} 
					/>
				</Stack>
				{/* resume */}
				<Stack spacing={4} direction="row">
					{/* transformation */}
					<Stack spacing={1}>
						<StyledItemLabel>
							Type d’étape
						</StyledItemLabel>
						<StyledItemValue>
							{getTransformationTypeLabel(transformation)}
						</StyledItemValue>
					</Stack>
					{/* recipe & section */}
					<Stack spacing={1}>
						<StyledItemLabel>
							Recette (section)
						</StyledItemLabel>
						<StyledItemValue>
							{uniqueCode} - {recipe} <span style={{ color: "#7C7C7C" }}>({section})</span>
						</StyledItemValue>
					</Stack>
				</Stack>
			</Stack>
			{onClickComments && <Box sx={sx.commentsIconContainer}><CommentsIconBadge onToggle={onClickComments} count={commentsCount} /></Box>}
		</PEPSEResumeContainer>
	)
}

export default PSEResume