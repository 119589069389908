import React, { useState, useEffect, useCallback } from "react"
import DataGrid from "../../components/DataGrid/DataGrid"
import { Box } from "@mui/material"
import dayjs from "dayjs"
import {
	LOT_INVENTORY_MODE,
	LOT_OUTPUT_MODE,
	LOT_RECEPTION_MODE,
	LOT_DAMAGE_MODE,
	LOT_MODES_LABELS,
	LOT_DISPATCH_MODE,
	LOT_RETURN_MODE
} from "../../utils/lotInventoryUtils"
import {capitalizeFirstLetter} from "../../utils"
import { cloneDeep } from "lodash"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(() => ({
	unitInformation: {
		marginLeft: -4
	}
}))

const _computeRemainingQuantity = (lot, event) => {
	if ([LOT_INVENTORY_MODE, LOT_RECEPTION_MODE].includes(event.mode)) {
		return event.quantity
	}
	let quantityBeforeEvent = lot.initialQuantity
	const previousEvents = lot.events.filter(e => e.date < event.date)
	previousEvents.forEach((e) => {
		if (e.mode === LOT_OUTPUT_MODE || e.mode === LOT_DAMAGE_MODE || e.mode === LOT_DISPATCH_MODE || e.mode === LOT_RETURN_MODE) {
			quantityBeforeEvent -= e.quantity
		}
		if (e.mode === LOT_RECEPTION_MODE || e.mode === LOT_INVENTORY_MODE) {
			quantityBeforeEvent = e.quantity
		}
	})
	return quantityBeforeEvent - event.quantity
}

const _getEventLabel = (event) => {
	if (event.mode === LOT_OUTPUT_MODE) {
		return `Sortie du ${dayjs(event.productionDate).format("DD/MM/YYYY")}`
	}
	return LOT_MODES_LABELS[event.mode]
}

const createData = (event, lot) => {
	return {
		eventDate: dayjs.tz(event.date).format("DD/MM/YYYY HH:mm"),
		eventType: _getEventLabel(event),
		quantity: [LOT_OUTPUT_MODE, LOT_DAMAGE_MODE].includes(event.mode) ? - event.quantity : event.quantity,
		remainingQuantity: _computeRemainingQuantity(lot, event),
		user: event.user?.username ? event.user.username.substring(0, event.user.username.indexOf("@")) : "Inconnu"
	}
}

const columnHeaders = [
	{
		key: "eventDate",
		label: "Date du mouvement",
		filterable: false
	},
	{
		key: "eventType",
		label: "Type de mouvement",
		filterable: false
	},
	{
		key: "quantity",
		label: "Quantité concernée",
		filterable: false
	},
	{
		key: "remainingQuantity",
		label: "Quantité restante",
		filterable: false
	},
	{
		key: "user",
		label: "Utilisateur",
		filterable: false
	}
]

const LotEventsTable = ({ lot }) => {

	const [data, setData] = useState([])
	const classes = useStyles()

	// most recent on top
	const sortEvents = (events) => {
   return events.sort((a, b) => b.date - a.date)
	}

	useEffect(() => {
		const events = lot.events || []
		const clonedEvents = cloneDeep(events)
		const sortedEvents = sortEvents(clonedEvents)
		setData(sortedEvents.map((event) => createData(event, lot)))
	}, [lot])

	const renderRecapRow = useCallback(() => {
		const stockUnits = lot.orderSupplierItem?.units?.stock || {}
		let unitsInformation = ""
		if (stockUnits.unity.name) {
			unitsInformation = `(${capitalizeFirstLetter(stockUnits.unity.name)} ${stockUnits.unity.quantity}`
			unitsInformation += stockUnits.unity.quantity > 1 ? " pièces)" : " pièce)"
		}
		else if (stockUnits.unity.quantity) {
			unitsInformation = `(${stockUnits.unity.quantity}`
			unitsInformation += stockUnits.unity.quantity > 1 ? " pièces)" : " pièce)"
		}
		return (
			<Box sx={{
				display: "flex",
				padding: "16px",
				backgroundColor: "#EBF7EE",
				color: "#262626",
				alignItems: "center",
				gap: "8px",
				alignSelf: "stretch",
				fontSize: "16px",
				lineHeight: "22px",
				"& :first-child": {
					fontSize: 14
				}
			}}>
				<span>Quantité restante:</span>
				<strong>
					{lot.quantity}
				</strong>
				<span className={classes.unitInformation}>{unitsInformation}</span>
			</Box>)
	}, [lot])

	return (
		<DataGrid
			withFilters={false}
			title="Mouvements de stock"
			data={data}
			columns={columnHeaders}
			renderRecapRow={renderRecapRow}
		/>
	)

}

export default LotEventsTable