import React, { useRef, useState } from "react"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    DialogContentText,
    InputAdornment,
    Stack,
} from "@mui/material"
import clsx from "clsx"
import makeStyles from "@mui/styles/makeStyles"
import { COLORS } from "../../../utils"
import { getPSEWeightGap } from "../../../utils/productionStepExecution"

const schema = Yup.object().shape({
    weight: Yup.number().required("Veuillez indiquer le poids à reproduire."),
})

const useStyles = makeStyles({
    dialog: {
        width: 555,
        padding: 32,
    },
    confirmDialog: {
        width: 500,
        padding: 24
    },
    dialogTitle: {
        marginBottom: 33,
    },
    label: {
        fontSize: 14,
        fontWeight: 400,
        color: COLORS.BLACK_500,
        lineHeight: 1.57,
    },
    value: {
        color: COLORS.BLACK800,
    },
    unit: {
        color: COLORS.BLACK_400,
    },
    textField: {
        "& .MuiInputLabel-root": {
            fontSize: 19,
            color: COLORS.BLACK_500,
            lineHeight: 1.57,
        },
    }
})

const EditInsufficientWeightDialogForm = ({ onClose, open, onConfirm, productionStepExecution }) => {
    const [values, setValues] = useState(null)
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)

    const formikRef = useRef()

    const classes = useStyles()

    const handleValidateForm = () => {
        formikRef.current.submitForm()
    }

    const toggleOpenConfirmDialog = () => setOpenConfirmationDialog(value => !value)

    const handleSubmit = (values) => {
        setValues(values)
        toggleOpenConfirmDialog()
    }

    const handleCancel = () => {
        onClose()
    }

    const handleConfirm = async () => {
        await onConfirm(values)
        handleCancel()
        toggleOpenConfirmDialog()
    }

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" classes={{ paper: classes.dialog }}>
                <DialogContent sx={{ px: 0, pb: 0 }}>
                    <DialogContentText id="form-dialog-title" className={classes.dialogTitle}>
                        Indiquez la quantité que vous souhaitez mettre en reproduction pour compléter votre recette
                    </DialogContentText>
                    <Stack spacing={3}>
                        <Stack spacing={0}>
                            <span className={classes.label}>Poids manquant</span>
                            <Stack direction="row" alignItems="center">
                                <span className={clsx(classes.label, classes.value)}>
                                    {getPSEWeightGap(productionStepExecution)}
                                </span>
                                <span className={clsx(classes.label, classes.unit)}>&nbsp;kg</span>
                            </Stack>
                        </Stack>
                        <Formik
                            innerRef={formikRef}
                            onSubmit={handleSubmit}
                            validationSchema={schema}
                            initialValues={{ weight: "" }}
                        >
                        {({
                            values,
                            handleChange,
                            errors,
                        }) => {
                            return (
                                <Form>
                                    <TextField
                                        variant="standard"
                                        name="weight"
                                        label="Poids à reproduire"
                                        values={values.weight}
                                        error={!!errors?.weight}
                                        helperText={errors?.weight}
                                        onChange={handleChange}
                                        className={classes.textField}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            disableUnderline: false,
                                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                            type: "number",
                                        }}
                                    />
                                </Form>
                            )}}
                        </Formik>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ px: 0, pb: 0, mt: 6 }} className="flexRow spaceBetween">
                    <Button onClick={handleCancel} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleValidateForm} color="primary" variant="contained">
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>

            {/* confirm dialog */}
            <Dialog open={openConfirmationDialog} onClose={toggleOpenConfirmDialog} aria-labelledby="form-confirm-dialog" classes={{ paper: classes.confirmDialog }}>
                <DialogContent sx={{ px: 0, pb: 0, pt: 0 }}>
                    <DialogContentText id="form-confirm-dialog">
                        Vous avez choisi de reproduire 30 kg pour cette étape. Confirmez-vous cette action ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ px: 0, pb: 0, mt: 3 }} className="flexRow">
                    <Button onClick={toggleOpenConfirmDialog} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleConfirm} color="primary">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EditInsufficientWeightDialogForm