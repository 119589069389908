import Parse from "parse"
import dayjs from "dayjs"
import { onEnter, push, getReplacingPath, haveAccessRight, actionWithLoader } from "../Utils/utils"
import { getSitesWithKitchenAreas } from "../../parseManager/site/parseSiteManager"
import moment from "moment"
import { uniq, uniqBy } from "lodash"

import {
	getProductionStepExecutionSelector,
	getProductionStepsKitchenAreaSelector,
	getProductionStepsProductionDaySelector,
	getProductionStepsSiteSelector
} from "../../reducers/Production/ProductionSteps"
import {
	getAllPSEWithCommentsBySection,
	getFilteredProductionStepExecutions,
	getProductionStepExecution,
	saveProductionStepsExecutionsWithHours
} from "../../parseManager/productionStepExecutions/parseProductionStepExecutionManager"
import { openAppMessageSnackBar, showError } from "../Utils/app"
import { computeFirstSubStatus, editableHourToTimestamp } from "../../utils/productionStepExecution"
import { getMachinesByKitchenArea } from "../../parseManager/machines/machinesManager"
import { KitchenArea } from "../../parseManager/kitchenAreas/kitchenAreasManager"
import { getSecondaryDLCsForProductionStepExecution } from "../../parseManager/secondaryDLC/parseSecondaryDLCManager"
import { groupLotsBySupplierItems } from "../../utils/lotsUtils"
import { COLLECTION_NAMES } from "../../parseUtils"
import { groupSecondaryDLCsBySupplierItems } from "../../utils/secondaryDLCsUtils"
import { getProductionSuppliesFromSupplierItemAndProductionDate } from "../../parseManager/productionSupply/parseProductionSupplyManager"

const baseUrl = "/productionStepExecutions"

export const PRODUCTION_STEPS_SUPERVISION_URLS = {
	root: baseUrl,
	filter: `${baseUrl}/filter`,
	read: `${baseUrl}/consultation`,
	hoursEdit: `${baseUrl}/hours`,
	preparation: `${baseUrl}/preparation`,
	productionStepExecution: `${baseUrl}/productionStepExecution`
}

export const PSE_STATUS = {
	locked: "LOCKED",
	todo: "TODO",
	inProgress: "IN_PROGRESS",
	done: "DONE",
	toTest: "TO_TEST",
}


export const generateProductionStepExecutions = ({ productionDate, recipeLabel = null, recipeId = null, isNew = true }) => {
	return actionWithLoader(async (dispatch) => {
		try {
			const result = await Parse.Cloud.run("createProductionStepExecutionsByProductionItems", { productionDate, recipeId, isNew })
			const productionDateFormatted = dayjs(productionDate).format("DD/MM/YYYY")
			if (!result.success) {
				throw new Error("Une erreur est survenue lors de la generation des étapes de production du " + productionDateFormatted)
			}

			const message = recipeId !== null ?
				"Les étapes du " + recipeLabel + " en production le " + productionDateFormatted + " ont bien été régénérées" :
				"Les étapes de production ont bien été générées pour la journée de production " + productionDateFormatted

			dispatch(
				openAppMessageSnackBar(message)
			)
		} catch (error) {
			dispatch(openAppMessageSnackBar(error.message, "error"))
		}
	})
}

export const doPSEsExist = async (productionDate) => {
	const response = await Parse.Cloud.run("doPSEsExist", { productionDate })

	return response?.success ? response.exists : null
}

export const loadProductionStepsSupervisionFilters = () => {
	return actionWithLoader(async (dispatch) => {
		const sites = await getSitesWithKitchenAreas()
		const localStorage = window.localStorage
		const selectedSiteFromStorage = localStorage.getItem("selectedProductionStepsSite")
		const selectedKitchenAreaFromStorage = localStorage.getItem("selectedProductionStepsKitchenArea")
		const selectedProductionDayFromStorage = localStorage.getItem("selectedProductionStepsProductionDay")
		const selectedSite = selectedSiteFromStorage ? JSON.parse(selectedSiteFromStorage) : sites[0]
		const selectedKitchenArea = selectedKitchenAreaFromStorage ? JSON.parse(selectedKitchenAreaFromStorage) : selectedSite.kitchenAreas.length ? selectedSite.kitchenAreas[0] : null
		const selectedProductionDay = selectedProductionDayFromStorage ? selectedProductionDayFromStorage : moment.utc().format("YYYY-MM-DD")

		dispatch({
			type: "FILTERS_LOADED_FOR_PRODUCTION_STEPS",
			selectedSite,
			selectedKitchenArea,
			selectedProductionDay
		})
		dispatch({
			type: "SITES_LOADED_FOR_PRODUCTION_STEPS",
			sites
		})
	})
}


export const updateProductionStepsFilters = (site, kitchenArea, productionDay) => {
	return async dispatch => {
		const localStorage = window.localStorage
		if (site) {
			localStorage.setItem("selectedProductionStepsSite", JSON.stringify(site))
		}
		if (kitchenArea) {
			localStorage.setItem("selectedProductionStepsKitchenArea", JSON.stringify(kitchenArea))
		}
		const formattedProductionDay = moment.utc(productionDay).format("YYYY-MM-DD")
		if (formattedProductionDay) {
			localStorage.setItem("selectedProductionStepsProductionDay", formattedProductionDay)
		}
		dispatch({
			type: "FILTERS_LOADED_FOR_PRODUCTION_STEPS",
			selectedSite: site,
			selectedKitchenArea: kitchenArea,
			selectedProductionDay: formattedProductionDay
		})
	}
}

export const loginProductionStepsSupervision = () => {
	return async (dispatch) => {
		dispatch(showProductionStepsSupervision())
	}
}

export const loadProductionStepsExecution = () => {
	return actionWithLoader(async (dispatch, getState) => {
		const state = getState()
		const productionDay = getProductionStepsProductionDaySelector(state)
		const site = getProductionStepsSiteSelector(state)
		const kitchenArea = getProductionStepsKitchenAreaSelector(state)
		const filters = { productionDay, site, kitchenArea }

		// retrieve productionStepExecutions for given day
		const productionStepsExecutions = await getFilteredProductionStepExecutions(filters)
		// design recipe and section options from productionStepExecutions for given day
		const sectionOptions = productionStepsExecutions.reduce((acc, productionStepsExecution) => {
			const section = productionStepsExecution.section
			if (section) {
				acc.push({ label: productionStepsExecution.sectionName, key: productionStepsExecution.sectionName, value: section.objectId })
			}
			return acc
		}, [])

		const recipeOptions = productionStepsExecutions.reduce((acc, productionStepsExecution) => {
			const recipe = productionStepsExecution.recipe
			if (recipe) {
				const label = `${productionStepsExecution.uniqueCode} - ${productionStepsExecution.recipeName}`
				acc.push({ label, key: label, value: recipe.objectId })
			}
			return acc
		}, [])
		const formattedSectionOptions = uniqBy(sectionOptions, "key")
		const formattedRecipeOptions = uniqBy(recipeOptions, "key")

		dispatch({
			type: "PRODUCTION_STEPS_EXECUTIONS_LOADED",
			productionStepsExecutions
		})
		dispatch({
			type: "RECIPE_OPTIONS_LOADED_FOR_PRODUCTION_STEPS",
			recipeOptions: formattedRecipeOptions
		})
		dispatch(({
			type: "SECTION_OPTIONS_LOADED_FOR_PRODUCTION_STEPS",
			sectionOptions: formattedSectionOptions
		}))
	})
}

/**
 * 
 * @param {Array} supplierItems // original supplier items from pse
 * @param {Array} substitutesSupplierItems // substitutes supplier items from pse (from REMPLACEMENT DANS UNE PRODUCTION)
 * @param {String} kitchenAreaId // kitchen area id
 * @param {String} productionDate // production date
 * @returns 
 */

export const loadPSELotsAndSecondaryDLCsSelection = ({ supplierItems, substitutesSupplierItems = [], kitchenAreaId, productionDate }) => {
	return async dispatch => {

		const supplierItemsIds = supplierItems.map((supplierItem) => {
			const replacementSupplierItem = substitutesSupplierItems.find(substitute => substitute.originalSupplierItem.objectId === supplierItem.objectId)?.replacementSupplierItem
			if (replacementSupplierItem) {
				return replacementSupplierItem.objectId
			}
			return supplierItem.objectId
		})

		const [productionSupplies, productionSuppliesReplacement] = await Promise.all([
			getProductionSuppliesFromSupplierItemAndProductionDate({
				productionDate,
				supplierItemsIds,
				toJSON: false
			}),
			getProductionSuppliesFromSupplierItemAndProductionDate({
				productionDate,
				supplierItemsIds,
				replacement: true,
				toJSON: false
			})])

		const allProductionSupplies = productionSupplies.concat(productionSuppliesReplacement)

		const lots = allProductionSupplies.flatMap(productionSupply => productionSupply.get("lots")).map(lot => lot.toJSON())

		const lotsBySupplierItems = groupLotsBySupplierItems(lots, supplierItemsIds, productionDate)

		const secondaryDLCs = await getSecondaryDLCsForProductionStepExecution({
			kitchenAreaId,
			supplierItemsIds,
			productionSuppliesIds: uniq(allProductionSupplies.concat(productionSuppliesReplacement).map(productionSupply => productionSupply.id))
		})

		const secondaryDLCsBySupplierItems = groupSecondaryDLCsBySupplierItems(secondaryDLCs, supplierItemsIds)


		dispatch({
			type: "LOAD_PRODUCTION_STEP_EXECUTION_LOTS_SECONDARYDLCS",
			lotsBySupplierItems,
			secondaryDLCsBySupplierItems
		})
	}
}

export const loadProductionStepExecution = (params) => {
	return actionWithLoader(async (dispatch) => {
		// for the header
		const selectedSiteFromStorage = localStorage.getItem("selectedProductionStepsSite")
		const selectedKitchenAreaFromStorage = localStorage.getItem("selectedProductionStepsKitchenArea")
		const selectedProductionDayFromStorage = localStorage.getItem("selectedProductionStepsProductionDay")

		dispatch({
			type: "FILTERS_LOADED_FOR_PRODUCTION_STEPS",
			selectedSite: JSON.parse(selectedSiteFromStorage),
			selectedKitchenArea: JSON.parse(selectedKitchenAreaFromStorage),
			selectedProductionDay: selectedProductionDayFromStorage
		})

		const productionStepExecution = await getProductionStepExecution(params.id)

		const productionStepExecutionsSupplierItems = productionStepExecution.productionStepSnapshot.stepComponents.map(stepComponent => stepComponent.supplierItem).filter(supplierItem => !!supplierItem)
		if (productionStepExecutionsSupplierItems.length && (productionStepExecution.status === PSE_STATUS.inProgress || productionStepExecution.status === PSE_STATUS.todo)) {
			await loadPSELotsAndSecondaryDLCsSelection({
				supplierItems: productionStepExecutionsSupplierItems,
				substitutesSupplierItems: productionStepExecution.substitutes,
				kitchenAreaId: JSON.parse(selectedKitchenAreaFromStorage).objectId,
				productionDate: productionStepExecution.stepProductionDate
			})(dispatch)
		}
		const allPSEComments = await getAllPSEWithCommentsBySection(productionStepExecution.section.objectId, productionStepExecution.productionDate)

		dispatch({
			type: "LOAD_PRODUCTION_STEP_EXECUTION",
			productionStepExecution,
			allPSEComments
		})
	})
}

export const updateProductionStepExecutionStatusToDone = (id, values) => {
	return actionWithLoader(async (dispatch) => {
		const productionStepExecution = await getProductionStepExecution(id, false)
		// --------- current pse --------- //
		productionStepExecution.set("endTime", dayjs.utc().valueOf())
		productionStepExecution.set("status", values.status)
		if (productionStepExecution.has("subStatus")) {
			productionStepExecution.unset("subStatus")
		}
		const newProductionStepExecution = await productionStepExecution.save()
		const productionStepExecutionJson = newProductionStepExecution.toJSON()

		// --- current pse ulteriorStep --- //
		if (newProductionStepExecution.has("ulteriorStep")) {
			const currentUlteriorStep = newProductionStepExecution.get("ulteriorStep")

			const ulteriorStep = await getProductionStepExecution(currentUlteriorStep.id, false)
			if (ulteriorStep.has("priorSteps")) {
				const priorSteps = ulteriorStep.get("priorSteps")

				// set the ulteriorStep as TODO if all its priorSteps are DONE
				const isAllPriorStepsDone = priorSteps.every(priorStep => priorStep.step?.get("status") === PSE_STATUS.done)
				if (isAllPriorStepsDone) {
					ulteriorStep.set("status", PSE_STATUS.todo)
					const updatedUlteriorStep = await ulteriorStep.save()
					productionStepExecutionJson.ulteriorStep = updatedUlteriorStep.toJSON()
				}
			}
		}

		const allPSEComments = await getAllPSEWithCommentsBySection(productionStepExecutionJson.section.objectId, productionStepExecutionJson.productionDate)

		dispatch({
			type: "LOAD_PRODUCTION_STEP_EXECUTION",
			productionStepExecution: productionStepExecutionJson,
			allPSEComments
		})
	})
}

export const refreshProductionStepExecution = ({ pseId, include }) => {
	return actionWithLoader(async (dispatch) => {
		const productionStepExecution = await getProductionStepExecution(pseId, true, include)

		dispatch({
			type: "LOAD_PRODUCTION_STEP_EXECUTION",
			productionStepExecution
		})
	})
}

export const updateProductionStepExecutionLots = ({ id, secondaryDLCsIds, lotsIds, newSubStatus }) => {
	return actionWithLoader(async (dispatch) => {
		const productionStepExecution = await getProductionStepExecution(id, false)
		const lots = []
		secondaryDLCsIds.forEach(secondaryDLCId => {
			lots.push({
				__type: "Pointer",
				className: COLLECTION_NAMES.secondaryDLC,
				objectId: secondaryDLCId
			})
		})
		lotsIds.forEach(lotId => {
			lots.push({
				__type: "Pointer",
				className: COLLECTION_NAMES.lot,
				objectId: lotId
			})
		})
		productionStepExecution.set("lots", lots)
		if (newSubStatus) {
			productionStepExecution.set("subStatus", newSubStatus)
		}
		await productionStepExecution.save()

		// refresh to allow to include lots
		await refreshProductionStepExecution({ pseId: id })(dispatch)

	})
}

export const updateProductionStepExecutionFromToTestToDone = (id, values) => {
	return actionWithLoader(async (dispatch) => {
		const productionStepExecution = await getProductionStepExecution(id, false)
		// --------- current pse --------- //
		if (values.comment) {
			const newComment = {
				comment: values.comment,
				username: Parse.User.current() ? Parse.User.current().get("username") : null,
				creation_dt: dayjs.tz().valueOf()
			}

			/** update comments only for this current PSE **/
			const currentPSEComments = productionStepExecution.get("comments") ? productionStepExecution.get("comments") : []
			currentPSEComments.push(newComment)
			productionStepExecution.set("comments", currentPSEComments)
		}

		productionStepExecution.set("status", PSE_STATUS.done)
		const newProductionStepExecution = await productionStepExecution.save()
		const productionStepExecutionJson = newProductionStepExecution.toJSON()
		const allPSEComments = await getAllPSEWithCommentsBySection(productionStepExecutionJson.section.objectId, productionStepExecutionJson.productionDate)

		dispatch({
			type: "LOAD_PRODUCTION_STEP_EXECUTION",
			productionStepExecution: productionStepExecutionJson,
			allPSEComments
		})
	})
}

export const loadPSEMachinesOptions = ({ productionStepExecution, kitchenAreaId }) => {
	return async (dispatch) => {
		const kitchenAreaPointer = new KitchenArea()
		kitchenAreaPointer.id = kitchenAreaId
		const machines = await getMachinesByKitchenArea(kitchenAreaPointer)

		const newMachines = []
		for (const machine of machines) {
			const kitchenAreaMachineModel = machine.machineModel
			const pseMachineModels = productionStepExecution.productionStepSnapshot.machineType?.machineModels
			if (pseMachineModels) {
				const currentMachineModel = pseMachineModels.find(machineModel => machineModel.objectId === kitchenAreaMachineModel.objectId)
				if (currentMachineModel) {
					newMachines.push(machine)
				}
			}
		}

		dispatch({
			type: "LOAD_PSE_MACHINES_OPTIONS",
			machinesOptions: newMachines
		})
	}
}

export const loadProductionStepsExecutionToPrepare = () => {
	return actionWithLoader(async (dispatch, getState) => {
		const productionDay = getProductionStepsProductionDaySelector(getState())
		const site = getProductionStepsSiteSelector(getState())
		const kitchenArea = getProductionStepsKitchenAreaSelector(getState())

		const filters = { productionDay, site, kitchenArea, states: ["TODO", "LOCKED"] }
		const productionStepsExecutions = await getFilteredProductionStepExecutions(filters, ["recipe", "section", "productionStepSnapshot.machineType"])

		const kitchenAreaPointer = new KitchenArea()
		kitchenAreaPointer.id = kitchenArea.objectId
		const machines = await getMachinesByKitchenArea(kitchenAreaPointer)

		for (const productionStepsExecution of productionStepsExecutions) {
			const newMachines = []
			for (const machine of machines) {
				const kitchenAreaMachineModel = machine.machineModel
				const pseMachineModels = productionStepsExecution.productionStepSnapshot.machineType?.machineModels
				if (pseMachineModels) {
					const currentMachineModel = pseMachineModels.find(machineModel => machineModel.objectId === kitchenAreaMachineModel.objectId)
					if (currentMachineModel) {
						newMachines.push(machine)
					}
				}
			}
			productionStepsExecution.machinesOptions = newMachines.sort((a, b) => a.name.localeCompare(b.name))
		}

		dispatch({
			type: "PRODUCTION_STEPS_EXECUTIONS_LOADED",
			productionStepsExecutions
		})
	})
}

export const updateProductionStepExecutionSubStatus = ({ id, newSubStatus }) => {
	return actionWithLoader(async (dispatch) => {
		const productionStepExecution = await getProductionStepExecution(id, false)
		productionStepExecution.set("subStatus", newSubStatus)
		const newProductionStepExecution = await productionStepExecution.save()
		const productionStepExecutionJson = newProductionStepExecution.toJSON()

		dispatch({
			type: "LOAD_PRODUCTION_STEP_EXECUTION",
			productionStepExecution: productionStepExecutionJson
		})
	})
}

export const updateProductionStepExecutionsToInProgress = () => {
	return actionWithLoader(async (dispatch, getState) => {
		const productionStepExecution = getProductionStepExecutionSelector(getState())
		const productionStepExecutionObject = await getProductionStepExecution(productionStepExecution.objectId, false, [])

		productionStepExecutionObject.set("status", PSE_STATUS.inProgress)
		productionStepExecutionObject.set("subStatus", computeFirstSubStatus(productionStepExecution))
		productionStepExecutionObject.set("startTime", dayjs().utc().valueOf())
		await productionStepExecutionObject.save()
		const productionStepExecutionJson = productionStepExecutionObject.toJSON()

		const allPSEComments = await getAllPSEWithCommentsBySection(productionStepExecutionJson.section.objectId, productionStepExecutionJson.productionDate)

		dispatch({
			type: "LOAD_PRODUCTION_STEP_EXECUTION",
			productionStepExecution: productionStepExecutionJson,
			allPSEComments
		})
	})
}
// ------------------------- EDITION ------------------------- //
export const saveProductionStepsExecutionsEditedHours = (productionStepsExecutionsValues) => {
	return actionWithLoader(async () => {
		await saveProductionStepsExecutionsWithHours(productionStepsExecutionsValues)
	})
}

export const saveProductionStepsCoolingInformations = ({
	productionStepExecutionId,
	machinesBatch,
	newSubStatus
}) => {
	return actionWithLoader(async (dispatch) => {
		const productionStepExecution = await getProductionStepExecution(productionStepExecutionId, false)
		const formattedBatches = machinesBatch.map(batch => {
			return {
				machine: {
					__type: "Pointer",
					className: "Machine",
					objectId: batch.machineId
				},
				machineName: batch.machineName,
				startTemperature: batch.startTemperature,
				coolingStartTime: editableHourToTimestamp(batch.coolingStartTime, new Date())
			}
		})
		productionStepExecution.set("machinesBatch", formattedBatches)
		if (newSubStatus) {
			productionStepExecution.set("subStatus", newSubStatus)
		}

		const savedProductionStepExecution = await productionStepExecution.save()

		const productionStepExecutionJson = savedProductionStepExecution.toJSON()

		dispatch({
			type: "LOAD_PRODUCTION_STEP_EXECUTION",
			productionStepExecution: productionStepExecutionJson,
		})
	})
}

export const saveProductionStepExecutionEndCoolingInformations = ({
	productionStepExecutionId,
	machinesBatch,
	newSubStatus
}) => {
	return actionWithLoader(async (dispatch) => {
		const productionStepExecution = await getProductionStepExecution(productionStepExecutionId, false)
		const formattedBatches = machinesBatch.map(batch => {
			return {
				machine: {
					__type: "Pointer",
					className: "Machine",
					objectId: batch.machineId
				},
				machineName: batch.machineName,
				startTemperature: batch.startTemperature,
				coolingStartTime: batch.coolingStartTime,
				endTemperature: batch.endTemperature,
				coolingEndTime: editableHourToTimestamp(batch.coolingEndTime, new Date())
			}
		})
		productionStepExecution.set("machinesBatch", formattedBatches)
		if (newSubStatus) {
			productionStepExecution.set("subStatus", newSubStatus)
		}

		const savedProductionStepExecution = await productionStepExecution.save()

		const productionStepExecutionJson = savedProductionStepExecution.toJSON()

		dispatch({
			type: "LOAD_PRODUCTION_STEP_EXECUTION",
			productionStepExecution: productionStepExecutionJson,
		})
	})
}

export const saveProductionStepExecutionEndCookingInformations = ({
	productionStepExecutionId,
	machinesBatch,
	newSubStatus
}) => {
	return actionWithLoader(async (dispatch) => {
		const productionStepExecution = await getProductionStepExecution(productionStepExecutionId, false)
		const formattedBatches = machinesBatch.map(batch => {
			return {
				machine: {
					__type: "Pointer",
					className: "Machine",
					objectId: batch.machineId
				},
				machineName: batch.machineName,
				endTemperature: batch.endTemperature,
				cookingEndTime: editableHourToTimestamp(batch.cookingEndTime, new Date())
			}
		})
		productionStepExecution.set("machinesBatch", formattedBatches)
		if (newSubStatus) {
			productionStepExecution.set("subStatus", newSubStatus)
		}

		const savedProductionStepExecution = await productionStepExecution.save()

		const productionStepExecutionJson = savedProductionStepExecution.toJSON()

		dispatch({
			type: "LOAD_PRODUCTION_STEP_EXECUTION",
			productionStepExecution: productionStepExecutionJson,
		})
	})
}

export const saveProductionStepExecutionWeightValidationInformation = ({
	productionStepExecutionId,
	netWeight,
	newSubStatus
}) => {
	return actionWithLoader(async (dispatch) => {
		const productionStepExecution = await getProductionStepExecution(productionStepExecutionId, false)
		productionStepExecution.set("netWeight", netWeight)

		if (newSubStatus) {
			productionStepExecution.set("subStatus", newSubStatus)
		}
		const savedProductionStepExecution = await productionStepExecution.save()

		const productionStepExecutionJson = savedProductionStepExecution.toJSON()

		dispatch({
			type: "LOAD_PRODUCTION_STEP_EXECUTION",
			productionStepExecution: productionStepExecutionJson,
		})

	})
}

export const updateProductionStepExecutionComments = (id, comments) => {
	return actionWithLoader(async (dispatch) => {
		const productionStepExecution = await getProductionStepExecution(id, false)
		// --------- current pse --------- //
		if (comments && comments.length > 0) {
			productionStepExecution.set("comments", comments)
		}

		const newProductionStepExecution = await productionStepExecution.save()
		const productionStepExecutionJson = newProductionStepExecution.toJSON()
		const allPSEComments = await getAllPSEWithCommentsBySection(productionStepExecutionJson.section.objectId, productionStepExecutionJson.productionDate)

		dispatch({
			type: "LOAD_PRODUCTION_STEP_EXECUTION",
			productionStepExecution: productionStepExecutionJson,
			allPSEComments
		})
	})
}

export const substituteSupplierItemsInProductionStepExecutions = (values) => {
	return actionWithLoader(async (dispatch) => {
		const { productionStepExecutions, originalSupplierItem, replacementSupplierItem, recipes, productionDate } = values
		try {
			await Parse.Cloud.run("substituteSupplierItemsInProductionStepExecutions", { productionStepExecutionsIds: productionStepExecutions, originalSupplierItemId: originalSupplierItem, replacementSupplierItemId: replacementSupplierItem })
			dispatch(
				openAppMessageSnackBar(`Le remplacement d’article dans ${recipes.length} recettes a bien été effectué pour la journée de production du ${dayjs(productionDate).format("DD/MM/YYYY")}`)
			)
		}
		catch (e) {
			dispatch(showError(e.message))
		}
	})
}
// -------------- ONENTER -------------- //
export const onEnterProductionStepsRead = (store) => {
	return onEnter({
		store,
		actionThunk: loadProductionStepsExecution
	})
}

export const onEnterProductionStepExecution = (store) => {
	return onEnter({
		store,
		actionThunk: loadProductionStepExecution
	})
}

export const onEnterProductionStepsHoursEdit = (store) => {
	return onEnter({
		store,
		actionThunk: loadProductionStepsExecutionToPrepare,
	})
}

export const onEnterProductionStepsSupervision = (store) => {
	return onEnter({
		store,
		actionThunk: loadProductionStepsSupervisionFilters,
		getReplacingPath: getReplacingPath({ needUser: true }),
		haveAccessRight: haveAccessRight({ readProductionSteps: true })
	})
}

// ---------- REDIRECTION ---------- //
export const showProductionStepsLogin = () => {
	return push(PRODUCTION_STEPS_SUPERVISION_URLS.filter)
}

export const showProductionStepsSupervision = () => {
	return push(PRODUCTION_STEPS_SUPERVISION_URLS.read)
}

export const showProductionStepsHoursEdit = () => {
	return push(PRODUCTION_STEPS_SUPERVISION_URLS.hoursEdit)
}

export const showProductionStepsPreparation = (id) => {
	return push(PRODUCTION_STEPS_SUPERVISION_URLS.preparation + "/" + id)
}

export const showProductionStepExecution = (id) => {
	return push(PRODUCTION_STEPS_SUPERVISION_URLS.root + "/" + id)
}

