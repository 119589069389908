import { cloneDeep } from "lodash"
import { isObject } from "lodash"
import dayjs from "dayjs"

export const creditNotesReasons = {
	TEMPERATURE: {
		key: "TEMPERATURE",
		label: "Écart de température"
	},
	NOT_PRESENTED: {
		key: "NOT_PRESENTED",
		label: "Ne s'est jamais présenté"
	},
	LATE_DELIVERY: {
		key: "LATE_DELIVERY",
		label: "Livraison hors délai"
	},
	MISSING_DOCUMENT: {
		key: "MISSING_DOCUMENT",
		label: "Documents manquants"
	},
	TRUCK_CLEANLINESS: {
		key: "TRUCK_CLEANLINESS",
		label: "Propreté du camion"
	},
	OTHER: {
		key: "OTHER",
		label: "Autre"
	},
	DLC: {
		key: "DLC",
		label: "DLC"
	},
	BROKEN: {
		key: "BROKEN",
		label: "Abîmé"
	},
	QTE_ORDER_DELIVERY: {
		key: "QTE_ORDER_DELIVERY",
		label: "Écart qté commandée - bon de livraison"
	},
	QTE_DELIVERY_RECEPTION: {
		key: "QTE_DELIVERY_RECEPTION",
		label: "Écart qté bon de livraison - réceptionnée"
	}
}

export const creditNotesType = {
	LOT: "LOT",
	DELIVERY: "DELIVERY",
	BROKEN: "BROKEN",
	DELIVERY_NOTE: "DELIVERY_NOTE"
}

export const formatCreditNote = (data) => {
	return {
		reason: data.reason,
		lot: data.lot || null,
		quantity: data.quantity || null,
		comment: data.comment || null,
		images: data.images || [],
		amount: data.amount || null
	}
}

export const generateUnderDeliveredCreditNote = (quantity, orderQuantity) => {
	return {
		type: creditNotesType.DELIVERY_NOTE,
		reason: "QTE_ORDER_DELIVERY",
		quantity: orderQuantity - quantity,
		comment: `Qté cmd: ${orderQuantity} // Qté BL: ${quantity}`,
		images: [],
		amount: 0
	}
}

export const generateUnderReceivedCreditNote = (supplierItem, receptionQuantity, BLQuantity) => {
	return {
		type: creditNotesType.DELIVERY_NOTE,
		reason: "QTE_DELIVERY_RECEPTION",
		quantity: BLQuantity - receptionQuantity,
		comment: `Qté BL: ${BLQuantity} // Qté récep: ${receptionQuantity}`,
		images: [],
		amount: parseFloat((supplierItem.deliveryNoteUnitPrice * (BLQuantity - receptionQuantity)).toFixed(6))
	}
}

export const prepareBillingCreditNotesFiltersForLoading = ({ filters, sortBy, sortField, sites, suppliers }) => {
	const clonedFilters = cloneDeep(filters)
	if (clonedFilters.receptionDateUnique) {
		clonedFilters.receptionPeriodStart = isObject(clonedFilters.receptionDateUnique) ? clonedFilters.receptionDateUnique.valueOf() : dayjs(clonedFilters.receptionDateUnique).startOf("day").valueOf()
		clonedFilters.receptionPeriodEnd = isObject(clonedFilters.receptionDateUnique) ? clonedFilters.receptionDateUnique.endOf("day").valueOf() : dayjs(clonedFilters.receptionDateUnique).endOf("day").valueOf()
}
	if (clonedFilters.receptionDate && !clonedFilters.receptionDateUnique) {
		clonedFilters.receptionPeriodStart = clonedFilters.receptionDate.start
		clonedFilters.receptionPeriodEnd = clonedFilters.receptionDate.end
		delete clonedFilters.receptionDate
}
	if (clonedFilters.site) {
		const retrievedSite = sites.find(site => site.name === clonedFilters.site)
		clonedFilters.site = retrievedSite
	}
	if (clonedFilters.supplier) {
		const retrievedSupplier = suppliers.find(supplier => supplier.name === clonedFilters.supplier)
		clonedFilters.supplier = retrievedSupplier
	}
	if (clonedFilters.isCreditNotesPaid) {
		clonedFilters.isCreditNotesPaid = [clonedFilters.isCreditNotesPaid === "Payé"]

	}
	clonedFilters.sortBy = sortBy
	clonedFilters.sortField = sortField
	return clonedFilters
}