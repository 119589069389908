import React, { useMemo } from "react"
import { makeStyles } from "@mui/styles"
import { Stack } from "@mui/material"
import { PSE_STATUS } from "../../../actions/ProductionSteps/ProductionStepExecutions"
import { roundNumber } from "../../../utils"
import { getPSEWeightGap, isPSEInsufficientWeight } from "../../../utils/productionStepExecution"

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #E6E6E6",
    padding: "24px",
    gap: "24px",
    borderRadius: "8px"
  },
  labelContainer: {
    flexDirection: "row",
    gap: "8px"
  },
  mainLabel: {
    color: "#262626",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "22px"
  },
  label: {
    color: "#555",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "22px"
  },
  list: {
    flexDirection: "row",
    gap: "24px"
  },
  headerCell: {
    color: "#555555",
    fontWeight: 400,
    paddingBottom: "8px !important",
    width: "150px"
  },
  value: {
    color: "#262626",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  error: {
    color: theme.palette.error.main
  }
}))

const PSEWeights = ({ productionStepExecution, isVisible = true, status }) => {

  const classes = useStyles()

  const items = useMemo(() => {
    const isInsufficientWeightInProgress = status === PSE_STATUS.inProgress && isPSEInsufficientWeight(productionStepExecution.subStatus)

    const data = []
    if ([PSE_STATUS.todo, PSE_STATUS.locked].includes(productionStepExecution.status)) {
      data.push({ key: "theoreticalNetWeight", label: "Poids final prévu", value: `${roundNumber(productionStepExecution.theoreticalNetWeight, 2)} kg` })
    }

    const percentageGap = roundNumber((productionStepExecution.netWeight - productionStepExecution.theoreticalNetWeight) / productionStepExecution.theoreticalNetWeight * 100, 2)
    
    if (status === PSE_STATUS.done || isInsufficientWeightInProgress) {
      data.push({
        key: "netTheoreticalWeight",
        label: "Poids final réel/prévu",
        value: (
          <>
            <span className={isInsufficientWeightInProgress && classes.error}>
              {roundNumber(productionStepExecution.netWeight, 2)}
            </span>  /  {roundNumber(productionStepExecution.theoreticalNetWeight, 2)} kg
          </>
        )
      })
      data.push({
        key: "gap",
        label: "Écart en valeur",
        value: getPSEWeightGap(productionStepExecution)
      })
      
      data.push({
        key: "gapPercentage",
        label: "Écart en %",
        value: <span className={isInsufficientWeightInProgress && classes.error}>{percentageGap} %</span>
      })
    }

    return data
  }, [status, productionStepExecution])

  if (!isVisible) {
    return null
  }

  return (
    <Stack className={classes.root}>
      <Stack className={classes.labelContainer}>
        <img src="/img/recipes/weight.svg" alt="Poids" />
        <span className={classes.mainLabel}>Poids</span>
      </Stack>
      <Stack className={classes.list}>
        {items.map((item, index) => (
          <Stack key={index} gap={1}>
            <div className={classes.label}>{item.label}</div>
            <div className={classes.value}>{item.value}</div>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )

}

export default PSEWeights